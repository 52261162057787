@import "./styles/init";
@import "~normalize.css/normalize.css";
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;500&display=swap');

$gradient: linear-gradient(135deg, blue 0%, yellow 100%);

body {
	font-family: 'DM Mono', sans-serif;
	background: black;
	background-image: radial-gradient(rgba(white, 0.1) 1px, transparent 0);
	background-size: 25px 25px;
}

.deems {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	perspective: 400px;

	.thumb-accent {
		background: $gradient;
		position: absolute;
		top: calc(50% - 132px);
		left: calc(50% - 132px);
		width: 264px;
		height: 264px;
		border-radius: 50%;
		z-index: -10;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: calc(50% - 132px);
			left: calc(50% - 132px);
			width: 264px;
			height: 264px;
			border-radius: 50%;
			background: $gradient;
			filter: blur(100px);
			opacity: 0.5;
		}

		&::before {
			box-shadow: 0 5px 10px rgba(black, 0.5);
			filter: none;
			z-index: 1;
		}
	}

	.deems-thumb {
		position: absolute;
		top: calc(50% - 120px);
		left: calc(50% - 120px);
		width: 240px;
		height: 240px;
		box-shadow: 0 0 0 4px black;
		border-radius: 50%;
		z-index: -10;
		transition:
			filter     250ms cubic-bezier(0.230, 1.000, 0.320, 1.000),
			box-shadow 250ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
		filter: brightness(0.5);
	}

	&.active .deems-thumb {
		filter: brightness(1);
		box-shadow: 0 0 0 8px black;
	}

	.link-container	{
		position: absolute;
		top: calc(50% - 12px);
		left: -50vw;
		right: 0;
		width: 100vw;
		height: 24px;
		text-align: center;
		mix-blend-mode: lighten;
		z-index: 100;

		a.link {
			display: inline-block;
			position: relative;
			color: white;
			text-decoration: none;
			font-weight: 700;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 4px;

			&::before,
			&::after {
				content: "";
				position: absolute;
				bottom: 8px;
				left: 0;
				width: 100%;
				height: 11px;
				background: black;
				transition: transform 250ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
				transform: scaleX(1);
				transform-origin: bottom left;
			}

			&::after {
				left: -8px;
				width: calc(100% + 16px);
				bottom: -4px;
				height: 34px;
				background: $gradient;
				transform: scaleX(0);
				transform-origin: bottom right;
				z-index: -1;
			}
		
			&:hover, &:focus {
				&::before {
					transform: scaleX(0);
					transform-origin: bottom right;
				}

				&::after {
					transform: scaleX(1);
					transform-origin: bottom left;
				}
			}

			@supports (background-clip: text) {
				color: white;
				// background: $gradient;
				// background-clip: text;
				// -webkit-text-fill-color: transparent;
			}
		}
	}
}
